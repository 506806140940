import moment from "moment";
import { getUserGPSAddressAction } from "../store/actions/userAction";
export const uid = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const getDateToday = () => {
  // console.log("moment date and time", moment().utc().format("DD/MM/YYYY"));
  return moment().utcOffset("+05:30").format("DD/MM/YYYY");
};

export const getFormattedDate = (date: any) => {
  return moment(date).utcOffset("+05:30").format("DD/MM/YYYY");
};

export const getDateNowInUtc = () =>
  moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");

export const getDateNowInUtcWithFormate = (str: string) =>
  moment().utc().format(str);

export const getOneImageForTemple = (imageArr: any) => {
  if (imageArr.length > 0) {
    let imageTemple = imageArr[0].pictureUrl;
    for (let i = 0; i < imageArr.length; i++) {
      if (imageArr[i].isPrimary) {
        imageTemple = imageArr[i].pictureUrl;
      }
    }
    return imageTemple;
  } else {
    return "";
  }
};

export const calculateTotalAmountForTemple = (
  basicPrice: any,
  commission: any
) => {
  const price = basicPrice + (basicPrice / 100) * 20 - commission;
  return price.toFixed(2);
};

export const calculateTDS = (basicPrice: any, commission: any) => {
  const tds =
    0.05 * parseFloat(calculateTotalAmountForTemple(basicPrice, commission));
  return tds.toFixed(2);
};

export const calculateActualAmountToPay = (
  basicPrice: any,
  commission: any
) => {
  const templePrice = calculateTotalAmountForTemple(basicPrice, commission);
  const tds = calculateTDS(basicPrice, commission);
  return (parseFloat(templePrice) - parseFloat(tds)).toFixed(2);
};

export const getDateFormatedOrderTime = (timeDate: any) => {
  //return moment(timeDate).format('hh:mm:ss a')

  return moment(timeDate).utc().local().format("DD-MM-YYYY hh:mm a");
};

export const getDateFormatedForBookingDate = (timeDate: any) => {
  //return moment(timeDate).format('hh:mm:ss a')
  // moment(timeDate).format('Do, hh:mm a');
  return moment(timeDate, "YYYY-MM-DD h:mmA").format("DD-MM-YYYY hh:mm a");
};

export const getDateFormatedForPujaDetails = (timeDate: any) => {
  //return moment(timeDate).format('hh:mm:ss a')
  // moment(timeDate).format('Do, hh:mm a');
  return moment(timeDate, "YYYY-MM-DD h:mmA").format("DD-MM-YYYY");
};

export const getCurrentTime = () => {
  //return moment(timeDate).format('hh:mm:ss a')
  // moment(timeDate).format('Do, hh:mm a');
  return moment().format("HH:mm a");
};

export const getDateFormatedForMUISelection = (timeDate: any) => {
  return moment(timeDate).format("YYYY-MM-DD 10:30A");
};

export const splitCamelCase = (str: string) => {
  // Using regular expression to split camelCase and periods
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\./g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const capitalizeWords = (inputString: string) => {
  const words = inputString?.split("_");
  let capitalizedString = "";
  if (words?.length) {
    capitalizedString = words
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  }

  return capitalizedString;
};

export const formatEmptyValue = (value: string) => {
  return typeof value != "undefined" || value != null || value != ""
    ? String(value)
    : " ";
};

export const filterUniqueUserEvents = (data: any) => {
  if (!data) {
    return data;
  }
  // const uniqueObjects: any = [];
  // const uniqueKeys = new Set();

  // data.forEach((item: any) => {
  //   const key = `${item?.eventData?.userId || item?.data?.user_id}-${item.key}`;
  //   if (!uniqueKeys.has(key)) {
  //     uniqueObjects.push(item);
  //     uniqueKeys.add(key);
  //   }
  // });
  // return uniqueObjects;

  const uniqueObjects = data.reduce((acc: any, obj: any) => {
    const key = `${
      obj?.eventData?.userId ||
      obj?.data?.user_id ||
      obj?.eventData?.whatsAppNumber
    }-${obj?.key}`;

    if (!acc[key]) {
      acc[key] = { count: 1, ...obj };
    } else {
      acc[key].count++;
    }
    return acc;
  }, {});
  const result = Object.values(uniqueObjects);
  return result;
};

export const convertToNormalString = (input: string) => {
  return input
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const removeFbclid = (url: string) => {
  // Find the index of fbclid in the URL
  const fbclidIndex = url.indexOf("fbclid");

  // If fbclid is found, remove it along with the characters after it
  if (fbclidIndex !== -1) {
    // Find the index of the next parameter separator ('&') after fbclid
    const nextParamIndex = url.indexOf("&", fbclidIndex);
    // If there are no more parameters after fbclid, remove everything from fbclid onwards
    const finalUrl =
      nextParamIndex !== -1
        ? url.slice(0, fbclidIndex)
        : url.slice(0, fbclidIndex - 1);
    return finalUrl;
  }

  // If fbclid is not found, return the original URL
  return url;
};

export const getFormattedTimeForDate = (date: any) => {
  let dateWithTime = moment(date)
    .utcOffset("+05:30")
    .format("DD-MM-YYYY hh:mm A");
  let time = dateWithTime.split(" ")[1] + " " + dateWithTime.split(" ")[2];
  return time;
};

export const getFormattedDateForTithi = (date: any) => {
  return moment(date).utcOffset("+05:30").format("DD/MM/YYYY hh:mm A");
};

export const validatePhoneNumber = (phone: any) => {
  const phoneRegex = /^(\+1|1)?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return phoneRegex.test(phone);
};

export const getCurrentPosition = async (dispatch: any) => {
  if (navigator.geolocation) {
    await navigator.geolocation.getCurrentPosition(
      (position: any) => {
        // console.log("User position",position)
        dispatch(
          getUserGPSAddressAction(
            position.coords.latitude,
            position.coords.longitude
          )
        );
      },
      (err: any) => console.log(err)
    );
    // console.log("location gpS", position);
  } else {
    navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
        if (permissionStatus.state === "denied") {
          alert("Please allow location access.");
        } else {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              // console.log("position data", position);
              dispatch(
                getUserGPSAddressAction(
                  position.coords.latitude,
                  position.coords.longitude
                )
              );
              // setPosition({
              //   latitude: position.coords.latitude,
              //   longitude: position.coords.longitude,
              // });
            },
            (err) => console.log(err)
          );
        }
      });
  }
};

export const generateTransactionId = () => {
  const timestamp = Date.now();
  const randomString = Math.random().toString(36).substring(2, 10); // Generate a random string
  const transactionId = `${timestamp}`;
  return transactionId;
};

export const generateUniqueNumberString = () => {
  const timestamp = new Date().getTime();
  const counter = Math.floor(Math.random() * 1000000);
  const uniqueNumberString = (timestamp + counter)
    .toString()
    .padStart(12, "0")
    .slice(-6);

  return uniqueNumberString;
};

export const createPaymentData = (
  data: any,
  phoneNumber: any,
  transectionId: any,
  orderId: any,
  finalPrice: any,
  key: any
) => {
  const secret = "FTwnPzEXFhfxSeEBrYxPRBBv";
  //const key = 'rzp_test_opDPOTIQs4H8jr';
  var options = {
    description: "Credits towards puja",
    image: "https://imgur.com/a/kA6KAUc",
    currency: "INR",
    key: key,
    amount: finalPrice * 100,
    name: "Temple Dekho",
    email: "appsadmin@omkarainfotech.com",
    order_id: orderId,
    contact: phoneNumber,
    remember_customer: true,
    captured: true,
    transectionId: transectionId,
    notes: {
      address: "Ground Floor, SJR Cyber, Laskar Hosur Road, Bengaluru",
    },
    save: 1,
    method: data.upiId ? "upi" : "card",
    prefill: {
      token_id: "token_N9qey7xk4mRcWH",
      email: "avinash@omkarainfotech.com",
      contact: phoneNumber,
      name: data.holderName,
      method: data.upiId ? "upi" : "card", //'method': 'netbanking', //card|upi|wallet
      save: 1,
      vpa: data.upiId ? data.upiId : "",
      "card[number]": data.cardNo ? data.cardNo : "",
      "card[expiry]": data.expMonth ? `${data.expMonth}/${data.expYear}` : "",
      // 'card[cvv]': '123',
    },
    theme: { color: "#d56939", hide_topbar: true },
  };

  return options;
};

export const PRICES = {
  adminCommission: 20, // value is in percentage
  delivery: 350, // value in amount
  packaging: 50, // value in amount
  goodies: 30, // value in amount
  platformFee: 20, // value is in percentage
  platformFeeForChadhava: 30, // value is in percentage
  gst: 18, // value is in percentage
  gst18: 18,
};

export const calculateAdminCommission = (price: any) => {
  const { adminCommission } = PRICES;
  const commission = (adminCommission / 100) * Number(price);
  return Math.round(commission);
};

export const calculatePlatformFee = (price: any) => {
  const { platformFee } = PRICES;
  const fee = (platformFee / 100) * Number(price);
  return Math.round(fee);
};

export const calculatePlatformFeeForChadhava = (price: any) => {
  const { platformFeeForChadhava } = PRICES;
  const fee = (platformFeeForChadhava / 100) * Number(price);
  return Math.round(fee);
};

export const calculatePujaPaymentPrice = (price: any, couponDiscount: any) => {
  const { delivery, packaging, goodies } = PRICES;
  const total =
    Number(price) -
    Number(couponDiscount) +
    Number(delivery) +
    Number(packaging) +
    Number(goodies) +
    calculateAdminCommission(price) +
    calculatePlatformFee(price);
  // console.log(
  //   'couponDiscount',
  //   price,
  //   delivery,
  //   packaging,
  //   goodies,
  //   calculatePlatformFee(price),
  //   calculateAdminCommission(price),
  //   price +
  //     delivery +
  //     packaging +
  //     goodies +
  //     calculateAdminCommission(price) +
  //     calculatePlatformFee(price),
  //   couponDiscount,
  // );

  // nee to check with legal team about gst calculatiom
  // removing basic price for gst as of now
  const tax = calculateGSTPrice(total, couponDiscount);
  const totalPrice = total + tax;
  return totalPrice;
};

export const calculateActualPujaPrice = (price: any) => {
  const { delivery, packaging, goodies } = PRICES;
  const total =
    Number(price) +
    Number(delivery) +
    Number(packaging) +
    Number(goodies) +
    calculateAdminCommission(price) +
    calculatePlatformFee(price);
  return total;
};

export const calculateGSTPrice = (price: any, couponDiscount: any) => {
  const { gst } = PRICES;
  const total = Number(price) - Number(couponDiscount);
  const tax = (Number(gst) / 100) * total;
  return Math.round(tax);
};

export const generatePanchangPayload = (addressInfo: any, date: any) => {
  // console.log("address info",addressInfo);
  //Its based on GPS data || currentAddress || Default value is set to howrah
  return {
    cityName: addressInfo?.address?.city || "Howrah,west bengal",
    config: { ayanamsha: "lahiri", observation_point: "topocentric" },
    date: date,
    hours: 22,
    indianDate: date,
    latitude: addressInfo?.address?.latitude || 22.5707175,
    longitude: addressInfo?.address?.longitude || 88.3214323,
    minutes: 0,
    month: 5,
    seconds: 0,
    timezone: 5.5,
    year: 2024,
  };
};

export const getDateToShow = (date: any) => {
  if (date === 1 || date === 21) {
    // console.log("Dateee", date, typeof date);
    return {
      dateNumber: date,
      subText: "st",
    };
  } else if (date === 2 || date === 22) {
    return {
      dateNumber: date,
      subText: "nd",
    };
  } else if (date === 3 || date === 23) {
    return {
      dateNumber: date,
      subText: "rd",
    };
  } else {
    return {
      dateNumber: date,
      subText: "th",
    };
  }
};

export const isMobile = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

// Function to get the ordinal suffix for a given day
const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) return "th"; // Covers 11th to 20th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDate = (date: string): string => {
  console.log("date", date);
  // Specify the date format if it's known
  const format = "DD-MM-YYYY"; // Format of the input date string

  // Parse the date with the specified format
  const momentDate = moment(date, format, true);

  // Check if the date is valid
  if (!momentDate.isValid()) {
    return "";
  }
  //const momentDate = moment(date);
  console.log("momentDate", momentDate);

  const day = momentDate.format("dddd");
  const dateOrdinal = momentDate.format("D");
  const suffix = getOrdinalSuffix(parseInt(dateOrdinal));
  const month = momentDate.format("MMMM");
  const year = momentDate.format("YYYY");

  return `${day}, ${dateOrdinal}${suffix} ${month}, ${year}`;
};

export const convertMinutesToOffset = (minutes: number): string => {
  const sign = minutes >= 0 ? "+" : "-";
  const absMinutes = Math.abs(minutes);
  const hours = Math.floor(absMinutes / 60);
  const mins = absMinutes % 60;
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(mins).padStart(2, "0");
  return `${sign}${formattedHours}:${formattedMinutes}`;
};

export const convertArrayOfObjectsToCSV = (data: any[]): string => {
  if (data.length === 0) return "";

  const headers = Object.keys(data[0]);
  const csv = [
    headers?.map((item) => splitCamelCase(item))?.join(","), // Add headers as the first line
    ...data.map((item) =>
      headers.map((fieldName) => JSON.stringify(item[fieldName])).join(",")
    ),
  ].join("\n");

  return "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
};

//CSV data

export const downloadExcelSheetFromObject = (
  filename: string,
  jsonData: any
) => {
  const csvData = jsonToCSV(jsonData);
  const blob = new Blob([csvData], { type: "text/csv" });
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = `${filename}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

function jsonToCSV(jsonData: any) {
  const headers = [
    "Key",
    "Puja Booking Date",
    "Puja Name",
    "Count",
    "Temple Name",
    "Phone Number",
    "User Id",
    "User Name",
    "Application Type",
    // "WhatsApp Number",
    "Whatsapp Number",
  ];

  //const headers = Object.keys(jsonData[0]);
  const csv = jsonData.map((row: any) =>
    headers.map((fieldName) => JSON.stringify(row[fieldName])).join(",")
  );
  return [headers.join(","), ...csv].join("\n");
}

export function getDevoteeSerial(no: any) {
  switch (no) {
    case 0:
      return "First";
    case 1:
      return "Second";
    case 2:
      return "Third";
    case 3:
      return "Fourth";
    case 4:
      return "Fifth";
    case 5:
      return "Sixth";
    case 6:
      return "Seventh";
    case 7:
      return "Eighth";
    case 8:
      return "Ninth";
    case 9:
      return "Tenth";
    case 10:
      return "Eleventh";
    case 11:
      return "Twelfth";
    case 12:
      return "Thirteenth";
    case 13:
      return "Fourteenth";
    case 14:
      return "Fifteenth";
    case 15:
      return "Sixteenth";
    case 16:
      return "Seventeenth";
    case 17:
      return "Eighteenth";
    case 18:
      return "Nineteenth";
    case 19:
      return "Twentieth";
    default:
      return "First";
  }
}

export const dayNameToNumber = (dayName: string): number | null => {
  const dayMapping: { [key: string]: number } = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
  };

  return dayMapping[dayName] !== undefined ? dayMapping[dayName] : null;
};

function getNextDayOfWeek(datesArray: string[]) {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return datesArray.map((date) => {
    if (daysOfWeek.includes(date)) {
      const targetDay = daysOfWeek.indexOf(date);
      const today = new Date();
      const currentDay = today.getDay();
      const daysUntilNext = (targetDay + 7 - currentDay) % 7 || 7;
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + daysUntilNext);
      return `Chadhava item not available for date: ${nextDate.toDateString()}`;
    } else {
      const specificDate = new Date(date);
      if (isNaN(specificDate.getTime())) {
        return `Invalid date format: ${date}`;
      }
      return `Chadhava item not available for date: ${specificDate.toDateString()}`;
    }
  });
}

export function calculateChadhavaPrice(basePrice: number) {
  const omkaraCommission = calculatePlatformFeeForChadhava(basePrice);
  const tax = calculateGstValue(omkaraCommission, PRICES.gst18);
  let response: any = {
    basePrice:Math.ceil(basePrice),
    calculatedPrice: Math.ceil(basePrice + omkaraCommission),
    gst: Math.ceil(tax),
    finalPrice: Math.ceil((basePrice + omkaraCommission + tax)),
    priceBreakup: {
      // This data is for calculating puja price without tax
      basePrice: Math.ceil(basePrice),
      delivery: 0,
      packaging: 0,
      goodies: 0,
      adminCommission: 0,
      platformFee: Math.ceil(omkaraCommission),
    },
  };

  return response;
}

const calculateGstValue = (price: any, gst = PRICES.gst18) => {
  const pujaPrice = Number(price);
  const tax = (Number(gst) / 100) * pujaPrice;
  return tax;
};

export const formatTimestamp = (timestamp: any, format: string) => {
  return moment(timestamp * 1000).format(format);
};

export const getDynamicPdfFileName = (data: any) => {
  const userName = data?.userBirthDetails?.fullName || "User";
  const date = new Date();
  const formattedDate = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;

  return `${data?.service}-report_${userName}_${formattedDate}.pdf`;
};
