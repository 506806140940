import React, { useEffect, useState } from "react";
import {
    Box,
    Switch,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";



const TempleView: React.FC<any> = (props) => {
    const [temples, setTemples] = useState<any>([]);

    useEffect(() => {

        setTemples(props.temples)

    }, [props.temples])



    const handleChadhavaToggle = (item: any) => {
        const temple = JSON.parse(item?.data) || null;
       props.handleChadhavaToggle(temple)
    };

    const handleDisableToggle = (item: any) => {
        const temple = JSON.parse(item?.data) || null;
        props.handleDisableToggle(temple)
       
    };

    const handleIsClosedBooking = (item: any) => {
        const temple = JSON.parse(item?.data) || null;
         props.handleIsClosedBooking(temple)
       
    };

    

    const columns = [
        {
            field: 'id',
            headerName: 'No.',
            width: 90,
            renderCell: (param: any) => `${param.row.id}`,
        },
        {
            field: 'nameKey',
            headerName: 'Temple Name',
            width: 450
        },
        {
            field: 'isChadhavaEnabled',
            headerName: 'Chadhava Enabled',
            width: 180,
            renderCell: (params: any) => (
                <Switch
                    checked={params.value}
                    onChange={(e) =>{e.preventDefault(); handleChadhavaToggle(params?.row)} }
                />
            ),
        },
        {
            field: 'isClosed',
            headerName: 'Temple Disabled',
            width: 180,
            renderCell: (params: any) => (
                <Switch
                    checked={params.value}
                    onChange={(e) => {e.preventDefault(); handleDisableToggle(params?.row)}}
                />
            ),
        },
        {
            field: 'isClosedBooking',
            headerName: 'Booking Puja Disabled',
            width: 180,
            renderCell: (params: any) => (
                <Switch
                    checked={params.value}
                    onChange={(e) => {e.preventDefault(); handleIsClosedBooking(params?.row)}}
                />
            ),
        },
    ];

    const rows: any[] =  Array.isArray(temples) ? temples?.map((temple: any, index = 0) => ({
        id: index + 1,
        nameKey: temple.personalInfo.name,
        isChadhavaEnabled: temple.isChadhavaEnabled,
        isClosed: temple.isClosed,
        isClosedBooking: temple.isClosedBooking,
        data:JSON.stringify(temple)
    })) : [];

    
    return (
        <Box className="pageContainer" mt={10}>
           
            <Box className="couponCodeView" sx={{ bgcolor: "#fff" }}>

                <DataGrid
                    className="couponDataGrid"
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                                page: 0,
                            },
                        },
                    }}
                    getRowId={(row) => row.id}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    autoHeight

                />
            </Box>

        </Box>
    );
};

export default TempleView;
