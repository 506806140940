import React, { useState, useRef, useEffect } from "react";
import verifytick from "../../assets/verify-tick.png";
import share from "../../assets/share.png";
import whitelike from "../../assets/whitelike.png";
// import "./TempleDetailsView.scss";
import GoogleMap from "../../components/map/GoogleMap";
import LeftAddressCard from "../../components/card/LeftBlock/LeftAddressCard";
import RightBlockCard from "../../components/card/RightBlock/RightBlock";
import TempleDetailsBanner from "../../components/banner/TempleDetailsBanner";
import TitleBlockCard from "../../components/card/TitleBlock/TitleBlock";
import viewPujaSteps from "../../assets/viewpujasteps.png";
import viewBless from "../../assets/bless-your-self.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  ButtonGroup,
  Dialog,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextArea from "../../components/textarea/Textarea";
import Typography from "@mui/material/Typography";
import ImageViewer from "../../components/imageviewer/ImageViewer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import ChadhavaBanner from "../../assets/chadhava-banner.jpg";
import templeIcon from "../../assets/temple-icon.png";
import locationIcon from "../../assets/loc-icon.png";
import TitleTypography from "../../components/typography/TitleTypography";
import ParagraphTypography from "../../components/typography/ParagraphTypography";
import SubtitleTypographyPrimary from "../../components/typography/SubtitleTypographyPrimary";
import MorPankh from "../../assets/morpankh.png";
import TempleFlag from "../../assets/temple-flag.png";

import RemoveIcon from "@mui/icons-material/Remove";
import { AddIcon } from "@chakra-ui/icons";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChadavaAddDialog from "../../components/dialog/ChadavaAddDialog";
import ShareLink from "../../components/sharelink/sharelink";
import SubtitleTypography from "../../components/typography/SubtitleTypography";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  UPCOMING_PUJA_CAROUSAL_RESPONSIVE,
  POPULAR_TEMPLE_CAUROSAL_RESPONSIVE,
  FEATURE_TO_DISPLAY_CAUROSAL_RESPONSIVE,
  CAROUSEL_REMOVE_ARRAOW_ON_DEVICE,
} from "../../utils/Constants";
import TestimonialCard from "../../components/card/Testimonial/TestimonialCard";
import "./Chadhava.scss";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import RoundedButton from "../../components/button/RoundedButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { typography } from "@chakra-ui/system";

function ChadhavaDetailsView(props: any) {
  // const checkDetails = props.templeDetails.

  const templeProfilePic = props.templeProfilePic();
  const [selectedItem, setSelectedItem] = useState<any>(null);

  // Chadhava Add Popup
  const [isPopupOpen, setPopupOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);

  const handleButtonClick = (el: any) => {
    setSelectedItem(el);
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  const [urlToShare, setUrlToShare] = useState<string>("");
  useEffect(() => {
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://" + window.location.hostname;
    setUrlToShare(
      `${hostname}/pagelink?chadhavaOftemple=${props?.templeDetails?.nameKey}`
    );
  }, [props.templeDetails]);

  const [templeName, setTempleName] = useState<string>("");
  const [templeAddress, setTempleAddress] = useState<string>("");
  const [templeDescriptions, setTempleDescriptions] = useState<string>("");
  const [chadhavaBenefits, setChadhavaBenefits] = useState<any>([]);
  const { city, state } = props.templePersonalInfo?.address || {};

  useEffect(() => {
    // if (props.templePersonalInfo?.address?.formatted_address) {
    //   setTempleAddress(props.templePersonalInfo?.address?.formatted_address);
    // }
    if (props.templePersonalInfo?.name) {
      setTempleName(props.templePersonalInfo?.name);
    }
    if (props.templePersonalInfo?.descriptions) {
      setTempleDescriptions(props.templePersonalInfo?.descriptions);
    }
  }, [props.templePersonalInfo]);

  useEffect(() => {
    if (props?.templeDetails?.chadhavaBenefits) {
      setChadhavaBenefits(props?.templeDetails?.chadhavaBenefits);
    }
  }, [props?.templeDetails]);

  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const increment = (item: any) => {
    props.handleIncrement(item);
  };

  const decrement = (item: any) => {
    props.handleDecrement(item);
  };
  const addChadhavaItem = (item: any) => {
    props.addChadhavaItem(item);
  };

  const [isAboveFooter, setIsAboveFooter] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector(".footerArea");
      const button = document.querySelector(".sticky-button");
      if (footer && button) {
        const footerRect = footer.getBoundingClientRect();
        const buttonRect = button.getBoundingClientRect();
        if (
          footerRect.top < window.innerHeight &&
          footerRect.bottom >= buttonRect.bottom
        ) {
          setIsAboveFooter(true);
        } else {
          setIsAboveFooter(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  let filteredArray = props.filteredChadhavaList || [];

  return (
    <Box className="pageContainer pujaPage">
      <Box className="pujaBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="pujainfoCenterBlock">
              <Box className="pujaCard">
                {/* <TitleTypography
                  title={`${props.pujaDetails?.pujaName}`}
                ></TitleTypography> */}
                <Box className="pujaCardBlock">
                  <Box className="pujaImgBlock">
                    <img src={templeProfilePic} alt=""></img>
                  </Box>
                  <Box className="pujaDescription">
                    {/* <Box className="pujaNameInfo">
                      <Typography>{templeName}</Typography>
                    </Box> */}
                    <Box className="chadhavaNameLocPrice">
                      <List>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className="twoItem"
                        >
                          <ListItem>
                            <ListItemAvatar sx={{ minWidth: "46px" }}>
                              <Avatar
                                alt="Temple Icon"
                                className="squreShape temple"
                              >
                                <img src={templeIcon}></img>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${templeName}`} />
                          </ListItem>
                          <Box className="shareIcon">
                            <ShareLink urlToShare={urlToShare}></ShareLink>
                          </Box>
                        </Stack>

                        <ListItem>
                          <ListItemAvatar sx={{ minWidth: "46px" }}>
                            <Avatar
                              alt="Location Icon"
                              className="squreShape location"
                            >
                              <img src={locationIcon}></img>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            sx={{ color: "black !important" }}
                            primary={`${city}, ${state}`}
                          />
                        </ListItem>
                        <ListItem></ListItem>
                      </List>
                      {/* <Box className=" desktopOnly">
                        <Stack
                          direction="row"
                          className="btnAddChadhava"
                          spacing={2}
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin:"0 3%",
                          }}
                        >
                          <Typography >₹{`${props?.totalPrice}`}/-</Typography>
                          <Box>
                            <Button
                              type="button"
                              onClick={() => {
                                props.setIsDateTimePicker(true);
                                if (props?.isSingleDatePuja) {
                                  //bookedSingleDatePuja()
                                } else {
                                  props.setIsDateTimePicker(true);
                                }

                                // props.navigateToPujaDetailsAdditionScreen()
                              }}
                            >
                              Continue
                            </Button>
                          </Box>
                        </Stack>
                      </Box> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="overViewText">
                <SubtitleTypographyPrimary title="Temple Details"></SubtitleTypographyPrimary>
                <ParagraphTypography
                  title={`${templeDescriptions}`}
                ></ParagraphTypography>
              </Box>

              <Box className="templeDekhoVideo">
                <SubtitleTypographyPrimary title="About “Temple Dekho”"></SubtitleTypographyPrimary>
                <Typography>
                  Please watch our feature video to discover the capabilities of
                  the <span>Temple Dekho</span> app.
                </Typography>
                <Button
                  onClick={() => props.navigateToSeeVideoView()}
                  variant="contained"
                  className="ChadhavSeeVideoBtn"
                >
                  See Video
                </Button>
              </Box>
              <Box className="chadhavaItemTitle" pt={2}>
                <SubtitleTypographyPrimary title="Chadhava items"></SubtitleTypographyPrimary>
              </Box>
              {filteredArray?.map((item: any, index: number) => (
                <Box
                  className={`chadhavaDetailsBlock chadhavaItems ${
                    item.disabled === true ? "boxDisable" : ""
                  }`}
                  key={"ChadhavaItem" + index}
                >
                  <Box className="chadhavaItem">
                    <Box className="chadhavaLeft">
                      <Typography>
                        {item?.name}
                        {item?.isMostOfferedTag && (
                          <span className="offerItem">Most offered</span>
                        )}
                      </Typography>

                      {/* <Typography>{item?.restrictionChadavaDate?.join(",    ")}</Typography> */}
                      {item?.disabled === true && (
                        <Typography sx={{ color: "red" }}>
                          {props.getAvilableChadhavaDate()}
                        </Typography>
                      )}
                      <ParagraphTypography title={item?.itemDescription} />
                      <TitleTypography
                        title={`₹ ${
                          item?.priceInfo?.finalPrice
                            ? Math.ceil(Number(item?.priceInfo?.finalPrice))
                            : "0.00"
                        }`}
                      />
                    </Box>
                    <Box className="chadhavaRight">
                      <Box className="itemImg">
                        <img src={item?.image}></img>
                      </Box>
                      {item?.count === 0 ? (
                        <Box className="btnAdd">
                          <Button
                            className="chadhavaAdd"
                            variant="outlined"
                            onClick={(e) => {
                              e.preventDefault();
                              addChadhavaItem(item);
                            }}
                          >
                            ADD
                          </Button>
                        </Box>
                      ) : (
                        <Box className="addItemsBtns">
                          <ButtonGroup className="btnsGroup">
                            <Button
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                decrement(item);
                              }}
                            >
                              <RemoveIcon fontSize="small" />
                            </Button>
                            <Button variant="contained">{item?.count}</Button>
                            <Button
                              variant="contained"
                              onClick={(e) => increment(item)}
                            >
                              <AddIcon fontSize="small" />
                            </Button>
                          </ButtonGroup>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}

              {/* <Box className="chadhavaDetailsBlock chadhavaItems">
                <Box className="chadhavaItem">
                  <Box className="chadhavaLeft">
                    <Typography>
                      Mor Pankh <span className="offerItem">Most offered</span>
                    </Typography>
                    <ParagraphTypography title="Devotees often offer peacock feathers to deities in temples as a gesture of…" />
                    <TitleTypography title="₹ 19.00" />
                  </Box>
                  <Box className="chadhavaRight">
                    <Box className="itemImg">
                      <img src={MorPankh}></img>
                    </Box>
                    <Box className="btnAdd">
                      <Button
                        className="chadhavaAdd"
                        variant="outlined"
                        onClick={handleButtonClick}
                      >
                        ADD
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box> */}

              <Box className="pujaBenefits">
                <SubtitleTypographyPrimary title="Benefits of Chadhava"></SubtitleTypographyPrimary>
                {chadhavaBenefits.map((item: any, id: any) => {
                  return (
                    <Box className="benefitsCard">
                      <SubtitleTypography
                        title={`${item.name}`}
                      ></SubtitleTypography>
                      <Box className="benefitCardImg">
                        <img src={`${item.url}`} alt=""></img>
                      </Box>
                      {item.description && (
                        <ParagraphTypography
                          title={`${item.description}`}
                        ></ParagraphTypography>
                      )}
                    </Box>
                  );
                })}
              </Box>

              <Box className="chadhavaProccessSteps">
                <SubtitleTypographyPrimary title="Chadhava Steps"></SubtitleTypographyPrimary>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            1
                          </Typography>

                          <Typography>
                            Select and add Chadhava items.
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            2
                          </Typography>

                          <Typography>
                            Provide Name and Gotram (Gotram is optional).
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            3
                          </Typography>

                          <Typography>Make payment</Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            4
                          </Typography>

                          <Typography>
                            {" "}
                            Skilled pandits will present the Chadhavas and
                            recite the Sankalp in your name within the temple or
                            its premises, as many temples have strict rules
                            against photography and video recording.
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            5
                          </Typography>

                          <Typography>
                            After the Sankalp ceremony, Pandit ji will proceed
                            to the main temple to present your offerings to the
                            deities in accordance with Vedic rituals.
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            6
                          </Typography>

                          <Typography>
                            Once the Chadhava is done, you will receive a video
                            on your WhatsApp number within 3-5 working days.
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                </List>
              </Box>

              {/* <Box className="chadhavaTestimonial">
                <SubtitleTypographyPrimary title="Devotee Experience"></SubtitleTypographyPrimary>
                <Carousel
                  responsive={FEATURE_TO_DISPLAY_CAUROSAL_RESPONSIVE}
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  keyBoardControl={true}
                  removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                >
                  <TestimonialCard />
                  <TestimonialCard />
                  <TestimonialCard />
                </Carousel>
              </Box> */}

              {/*<Box className="chadhavaFaq">
                <SubtitleTypographyPrimary title="Frequently Asked Questions"></SubtitleTypographyPrimary>
                
                 <Box className="chadhavaFaqList">
                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel1" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel1"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel1" ? "10px" : "0"
                      }}
                    >
                      Why should I choose Temple Dekho for temple Chadhava?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel2" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel2"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel2" ? "10px" : "0"
                      }}
                    >
                      Who is designated to offer the Chadhava?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel3" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel3"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel3-content"
                      id="panel3-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel3" ? "10px" : "0"
                      }}
                    >
                      I don’t know my Gotram, can I offer Chadhava?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel4" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel4"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel4-content"
                      id="panel4-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel4" ? "10px" : "0"
                      }}
                    >
                      Could I receive any proof of my Chadhava?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel5" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel5"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel5-content"
                      id="panel5-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                        // mb: expanded === "panel5" ? "10px" : "0"
                      }}
                    >
                      Who should I reach out to for more information?
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </AccordionDetails>
                  </Accordion>
                </Box> 
              </Box>*/}

              <Box
                className="chadhavaContinueBtn desktopOnly sticky-button"
                sx={{ position: `${isAboveFooter ? "absolute" : "fixed"}` }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {/* <Typography> ₹{`${ props?.totalPrice ? Number(props?.totalPrice).toFixed(2) : '0.00' }`}/-</Typography>
                   */}
                  <Typography>
                    {" "}
                    ₹
                    {`${
                      props?.totalPrice
                        ? Math.ceil(Number(props?.totalPrice))
                        : "0.00"
                    }`}
                    /-
                  </Typography>
                  <Button
                    className={
                      props?.totalPrice === 0
                        ? "desktopContinueBtnGrey"
                        : "desktopContinueBtn"
                    }
                    onClick={() => props?.setIsDateTimePicker(true)}
                    disabled={props?.totalPrice === 0}
                  >
                    {" "}
                    Continue to Book
                  </Button>
                </Stack>
              </Box>

              <Box className="blessContinueChadhava"></Box>
              <Box className="blessContinue">
                <Box className="viewBless">
                  <img src={viewBless}></img>
                </Box>
                <Box className="continueCart">
                  <Box className="continueCenterBlock">
                    <Box className="continueLt">
                      ₹{`${props?.totalPrice}`}/-
                    </Box>

                    <Box className="continueRt">
                      <Button
                        type="button"
                        disabled={props?.totalPrice === 0}
                        className={
                          props?.totalPrice === 0
                            ? "continueBtnDsbl"
                            : "continueBtn" 
                        }
                        onClick={() => {
                          if (props?.isSingleDatePuja) {
                            //bookedSingleDatePuja()
                          } else {
                            props.setIsDateTimePicker(true);
                          }
                          // props.navigateToPujaDetailsAdditionScreen()
                        }}
                      >
                        Continue
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            lg={3}
            xl={3}
            className="pagehdRightBlock"
          ></Grid>
        </Grid>
        <Dialog
          className="dateTimingSelection"
          onClose={() => props.setIsDateTimePicker(false)}
          open={props.isDateTimePicker}
        >
          <Box className="dateTimePopup">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <TitleTypography title="Select Date">Select Date</TitleTypography>
              <IconButton
                aria-label="close"
                onClick={() => props.setIsDateTimePicker(false)}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 6,
                  fontSize: "12px",
                  color: "#d56939",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>

            <Box className="calendarInfo">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  shouldDisableDate={props?.shouldDisableDate}
                  disablePast={true}
                  onChange={(value: any) => {
                    props.setPujaDateAndTime({
                      ...props.pujaDateAndTime,
                      pujaDate: value,
                    });
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box className="btnContinue">
              <RoundedButton
                title="Continue"
                disabled={
                  props.pujaDateAndTime && props.pujaDateAndTime.pujaDate
                    ? false
                    : true
                }
                onClick={() => {
                  props.setIsDateTimePicker(false);
                  if (props.cartInfo && props.cartInfo.length > 0) {
                    props.setPopupPujaAlreadyAdded(true);
                  } else {
                    props.preprocessingAndProceedToAddmemberScreen();
                  }
                  //
                }}
              ></RoundedButton>
            </Box>
          </Box>
        </Dialog>

        {/* <Dialog
          className="dateTimingSelection"
          onClose={() => props.setPopupPujaAlreadyAdded(false)}
          open={props.popupPujaAlreadyAdded}
        >
          <Box style={{ padding: "1em" }}>
            <Typography>
              A puja has already been added to your cart. Would you like to
              replace the puja with selected puja ?
            </Typography>
            <Box sx={{ textAlign: "end" }}>
              <Button onClick={() => props.setPopupPujaAlreadyAdded(false)}>
                No
              </Button>
              <Button
                onClick={() => props.preprocessingAndProceedToAddmemberScreen()}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Dialog> */}

        {/* {isPopupOpen && (
          <Box className="chadhavaAddOverlay">
            <Box
              className="addChadhavadialog"
              ref={popupRef}
              sx={{
                boxShadow: 24,
                p: 4,
              }}
            >
              <Box
                display="flex"
                justifyContent="flex-end"
                className="btnClose"
              >
                <IconButton onClick={handleClosePopup}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <ChadavaAddDialog
                chadhava={selectedItem}
                setChadhawaPrice={props?.setChadhawaPrice}
                handleClose={handleClosePopup}
              />
            </Box>
          </Box>
        )} */}
      </Box>
    </Box>
  );
}

export default ChadhavaDetailsView;
