import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Paper,
  makeStyles,
  Box,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./styles.scss";
import {
  getDateFormatedOrderTime,
  getDateFormatedForBookingDate,
} from "../../utils/HelperFunctions";
import { APPROLES, orderType } from "../../utils/Constants";
interface Data {
  [key: string]: string;
}

interface ViewAllDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  orderId: string;
}

const ViewAllDetailsModal: React.FC<ViewAllDetailsProps> = ({
  isOpen,
  onClose,
  orderId,
}) => {
  const { userInfo } = useSelector((state: any) => state.user);
  const { userOrder } = useSelector((state: any) => state.order);
  const [orderDetails, setOrderDetails] = React.useState<any>({});
  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (orderId && orderId.length > 0) {
      const orderDetails = userOrder.filter(
        (item: any) => item.orderId == orderId
      );
      setOrderDetails(orderDetails[0]);
    }
  }, [orderId]);

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userType === APPROLES.SUPERADMIN.roleId ||
        userInfo.userType === APPROLES.ADMIN.roleId)
    ) {
      setIsSuperAdmin(true);
    } else {
      setIsSuperAdmin(true);
      setExpandedAccordion("panel-3");
    }
  }, [userInfo]);

  const scrollRef: any = useRef(null);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      //scrollRef.scrollIntoView({ behavior: "smooth" })
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    //scrollToBottom();
  }, [orderDetails]);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : null);
      if (isExpanded) {
        scrollToBottom();
      }
    };

  console.log(`orderDetails----------------`, orderDetails);

  console.log(`orderDetails----------------`, orderDetails);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="change-status-title"
      className="orderModal"
    >
      <Box className="modalContents viewDetails">
        <Stack className="modalHeader">
          <Typography id="change-status-title" variant="h6" gutterBottom>
            Order Details : <span> {orderId} </span>
          </Typography>
          <Button onClick={onClose} variant="outlined" className="btnClose">
            <CloseIcon />
          </Button>
        </Stack>

        <Box className="modalBody">
          <Box ref={scrollRef} className="orderDetails">
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Order Id </span> :{" "}
              {orderDetails?.orderId}
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Temple Name </span> :{" "}
              {orderDetails?.templeDetails?.name}
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Puja Date </span> :{" "}
              {getDateFormatedForBookingDate(orderDetails?.pujaDate)}
            </Typography>
            {isSuperAdmin && (
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>Order Status </span> :{" "}
                {orderDetails?.orderStatus?.length
                  ? orderDetails?.orderStatus[
                      orderDetails?.orderStatus?.length - 1
                    ]?.orderStatus
                  : ""}
              </Typography>
            )}
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Order Booking Date </span> :{" "}
              {getDateFormatedOrderTime(orderDetails?.orderBookingDate)}
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Order User Name </span> :{" "}
              {orderDetails?.orderUserDetails?.orderUserName}
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Phone Number </span> :{" "}
              {orderDetails?.deliveryAddress?.phoneNumber &&
              orderDetails.deliveryAddress.phoneNumber.length > 3
                ? orderDetails?.deliveryAddress?.phoneNumber
                : orderDetails?.orderUserDetails?.phoneNumber}
            </Typography>

            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>WhatsApp Number </span> :{" "}
              {orderDetails?.whatsAppNumber}
            </Typography>

            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Puja Name </span> :{" "}
              {orderDetails?.pujaName}
            </Typography>
            {isSuperAdmin && (
              <>
                {" "}
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Payment Status </span> :{" "}
                  {orderDetails?.orderData?.payment?.paymentStatus?.toUpperCase()}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Transaction Id </span> :{" "}
                  {orderDetails?.orderData?.payment?.transactionId}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Payment Date </span> :{" "}
                  {getDateFormatedOrderTime(
                    orderDetails?.orderData?.payment?.paymentDateTime
                  )}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>Temple Amount</span> :{" "}
                  {Number(orderDetails?.orderData?.priceBreakup?.basic) +
                    Number(
                      orderDetails?.orderData?.priceBreakup?.adminCommission
                    )}
                </Typography>
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>TDS</span> :{" "}
                  {Number(orderDetails?.orderData?.priceBreakup?.basic) * 0.01}
                </Typography>
              </>
            )}
            {isSuperAdmin && (
              <Typography variant="body1">
                <span style={{ fontWeight: "bold" }}>Amount to pay</span> :{" "}
                {orderDetails?.orderData?.payment?.orderValue}
              </Typography>
            )}

            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Delivery Address </span> :{" "}
              {orderDetails?.deliveryAddress?.formatted_address}
            </Typography>

            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Puja Value</span> :{" "}
              {(
                Number(orderDetails?.orderData?.priceBreakup?.basic) +
                Number(orderDetails?.orderData?.priceBreakup?.adminCommission) +
                Number(orderDetails?.orderData?.priceBreakup?.goodies) +
                // Number(orderDetails?.orderData?.priceBreakup?.gst) +
                Number(orderDetails?.orderData?.priceBreakup?.packaging) +
                Number(orderDetails?.orderData?.priceBreakup?.platformFee) +
                Number(orderDetails?.orderData?.priceBreakup?.delivery)
              ).toFixed(2)}
            </Typography>

            <Box className="accordionList">
              {isSuperAdmin && (
                <Accordion
                  sx={{ boxShadow: "none" }}
                  expanded={expandedAccordion === `panel-1`}
                  onChange={handleChange("panel-1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accordHead"
                  >
                    <Typography variant="body1">
                      <span>Price Breakup</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordBody">
                    <Typography variant="body1">
                      <strong>Basic:</strong>{" "}
                      {Number(
                        orderDetails?.orderData?.priceBreakup?.basic
                      ).toFixed(2)}
                      <br />
                      <strong>Admin Commission:</strong>{" "}
                      {Number(
                        orderDetails?.orderData?.priceBreakup?.adminCommission
                      ).toFixed(2)}
                      <br />
                      <strong>Goodies:</strong>{" "}
                      {Number(
                        orderDetails?.orderData?.priceBreakup?.goodies
                      ).toFixed(2)}
                      <br />
                      <strong>GST:</strong>{" "}
                      {Number(
                        orderDetails?.orderData?.priceBreakup?.gst
                      ).toFixed(2)}
                      <br />
                      <strong>Packaging:</strong>{" "}
                      {Number(
                        orderDetails?.orderData?.priceBreakup?.packaging
                      ).toFixed(2)}
                      <br />
                      <strong>Platform Fee:</strong>{" "}
                      {Number(
                        orderDetails?.orderData?.priceBreakup?.platformFee
                      ).toFixed(2)}
                      <br />
                      <strong>Delivery:</strong>{" "}
                      {Number(
                        orderDetails?.orderData?.priceBreakup?.delivery
                      ).toFixed(2)}
                      <br />
                      <strong>Dakshina:</strong>
                      {orderDetails?.orderData?.priceBreakup?.dakshina ||
                        0}{" "}
                      <br />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}
              {/* <Accordion expanded={expandedAccordion === `panel-2`} onChange={handleChange('panel-2')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordHead'>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Delivery Address</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails className='accordBody'>
                <Typography variant="body1">
                  Address: {orderDetails?.deliveryAddress?.formatted_address}<br />
                  Area: {orderDetails?.deliveryAddress?.area}<br />
                  Street: {orderDetails?.deliveryAddress?.street}<br />
                  City: {orderDetails?.deliveryAddress?.city}<br />
                  State: {orderDetails?.deliveryAddress?.state}<br />
                  Phone Number: {orderDetails?.deliveryAddress?.phoneNumber}<br />
                  Saved As: {orderDetails?.deliveryAddress?.savedAs}<br />
                  Longitude: {orderDetails?.deliveryAddress?.longitude}<br />
                  Altitude: {orderDetails?.deliveryAddress?.altitude ? orderDetails?.deliveryAddress?.altitude : " "}<br />
                  Latitude: {orderDetails?.deliveryAddress?.latitude}<br />
                  Altitude Accuracy: {orderDetails?.deliveryAddress?.altitudeAccuracy ? orderDetails?.deliveryAddress?.altitudeAccuracy : ' '}<br />
                </Typography>
              </AccordionDetails>
            </Accordion> */}
              <Accordion
                sx={{ boxShadow: "none" }}
                expanded={expandedAccordion === `panel-3`}
                onChange={handleChange("panel-3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="accordHead"
                >
                  <Typography variant="body1">
                    <span>Puja Member Details</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordBody">
                  <TableContainer component={Paper}>
                    <Table aria-label="puja-member-details-table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableHeaderCell">
                            Full Name
                          </TableCell>
                          <TableCell className="tableHeaderCell">
                            Date of Birth
                          </TableCell>
                          <TableCell className="tableHeaderCell">
                            Birth Location
                          </TableCell>
                          <TableCell className="tableHeaderCell">
                            Gender
                          </TableCell>
                          <TableCell className="tableHeaderCell">
                            Rashi
                          </TableCell>
                          <TableCell className="tableHeaderCell">
                            Gotram
                          </TableCell>
                          <TableCell className="tableHeaderCell">
                            Puja Name
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderDetails?.orderUserDetails?.groupDetails?.map(
                          (member: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell>{member.fullName}</TableCell>
                              <TableCell>{member.dateOfBirth}</TableCell>
                              <TableCell>
                                {member.birthLocation
                                  ? member.birthLocation
                                  : " "}
                              </TableCell>
                              <TableCell>{member.gender}</TableCell>
                              <TableCell>{member.rashi}</TableCell>
                              <TableCell>
                                {member.gotram ? member.gotram : " "}
                              </TableCell>
                              <TableCell>{member.pujaName}</TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>

              {orderDetails?.orderType === orderType.chadhava && (
                <>
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    expanded={expandedAccordion === `panel-4`}
                    onChange={handleChange("panel-4")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="accordHead"
                    >
                      <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>
                          Chadhava Item List
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table aria-label="chadhavalist">
                          <TableHead>
                            <TableRow>
                              <TableCell className="tableHeaderCell">
                                Chadhava Image
                              </TableCell>
                              <TableCell className="tableHeaderCell">
                                Chadhava Item
                              </TableCell>
                              <TableCell className="tableHeaderCell">
                                Qty.
                              </TableCell>
                              <TableCell className="tableHeaderCell">
                                Price
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderDetails?.chadhavaList?.map(
                              (el: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <img
                                      width="50px"
                                      height="50px"
                                      src={el.image}
                                    ></img>
                                  </TableCell>
                                  <TableCell>{el?.name}</TableCell>
                                  <TableCell>{el?.count}</TableCell>
                                  <TableCell>
                                    {" "}
                                    {el.priceInfo?.finalPrice}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </Box>
          </Box>
        </Box>

        <Box className="modalFooter">
          <Button
            onClick={onClose}
            variant="outlined"
            className="btnCancel grey"
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewAllDetailsModal;
