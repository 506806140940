import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/x-date-pickers/AdapterDateFns";

import { Outlet, useNavigate } from "react-router-dom";
import SubtitleTypography from "../../../components/typography/SubtitleTypography";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";

import {
  getDateFormatedForPujaDetails,
  getDevoteeSerial,
} from "../../../utils/HelperFunctions";
import { useEffect, useState } from "react";
import React from "react";
import { orderType } from "../../../utils/Constants";

function AddDevoteeDetailsView(props: any) {
  const navigate = useNavigate();
  const { cartInfo } = props;
  const singleCartData = cartInfo && cartInfo.length > 0 ? cartInfo[0] : null;
  let maxMemberAllowed = props.membersInfo?.length || 0;
  if (
    (singleCartData?.pujaPackage?.maxMemberAllowed ||
      singleCartData?.pujaDetails?.maxMemberAllowed) &&
    singleCartData?.orderType == orderType.puja
  ) {
    maxMemberAllowed = Number(
      singleCartData?.pujaPackage?.maxMemberAllowed ||
        singleCartData?.pujaDetails?.maxMemberAllowed
    );
  }

  let previousUserBookList = props?.prevUserGroup || [];

  // --- For Add Optional Details Modal ---
  const [open, setOpen] = React.useState(false);
  const [validationMessage, setValidationMessage] = useState<string>(""); // State for validation message

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [timeOfBirth, setTimeOfBirth] = useState(null);

  const handleGenderChange = (event: any) => {
    setGender(event.target.value);
  };

  return (
    <Box className="firstStep AddDevoteeDetails">
      <Box className="chadhavaDetails">
        {singleCartData?.orderType === orderType.puja ? (
          <SubtitleTypography title="Puja Details"></SubtitleTypography>
        ) : (
          <SubtitleTypography title="Chadhava Details"></SubtitleTypography>
        )}
        <Box className="infoDevotee">
          <ErrorOutlineOutlinedIcon /> You can add maximum {maxMemberAllowed}{" "}
          devotee at a time.
        </Box>
        <Box className="addDevotee">
          <SubtitleTypography title="Add Devotee Details"></SubtitleTypography>
          <Box className="formGroup">
            {singleCartData?.orderType === orderType.puja && (
              <Box className="formField lightOpa">
                <label>Puja Name </label>
                <TextField
                  fullWidth
                  id="standard-helperText"
                  value={singleCartData?.pujaDetails?.pujaName}
                  variant="standard"
                />
              </Box>
            )}

            <Box className="formField lightOpa">
              <label>Temple Name</label>
              <TextField
                fullWidth
                id="standard-helperText"
                value={singleCartData?.templeDetails?.personalInfo?.name}
                variant="standard"
              />
            </Box>
            <Box className="formField lightOpa">
              <label>Puja Date</label>
              <TextField
                fullWidth
                id="standard-helperText"
                value={getDateFormatedForPujaDetails(
                  singleCartData?.pujaDateTime?.pujaDate
                )}
                variant="standard"
              />
            </Box>
            <Box className="formField">
              <label>Provide Your WhatsApp Number * </label>
              <TextField
                fullWidth
                name="WhatsappNumber"
                id="WhatsappNumber"
                className="whatsAppInput"
                value={props.whatsAppNumber}
                variant="standard"
                onChange={(e: any) => {
                  const numericValue = e.target.value
                    .replace(/\D/g, "")
                    .replace(/^0/, "")
                    .slice(0, 10);
                  props.setWhatsAppNumber(numericValue);
                }}
                error={!!props.whatsAppNumberError}
              />
              <FormHelperText
                className="errorText"
                style={{
                  display:
                    props?.whatsAppNumberError &&
                    (props?.whatsAppNumber === undefined ||
                      props?.whatsAppNumber?.length < 10)
                      ? "block"
                      : "none",
                }}
              >
                Please enter a valid 10-digit WhatsApp number.
              </FormHelperText>
            </Box>

            <Box className="formField">
              <Button
                className="addDevt"
                onClick={() => props.setPopUpDevoteePreviousBooking(true)}
              >
                <AddCircleRoundedIcon /> Add devotee from previous bookings
              </Button>
            </Box>
            {props.membersInfo.map((item: any, id: any) => {
              return (
                <div>
                  <Box className="formField mbAdd">
                    <label>
                      Full Name of {getDevoteeSerial(id)} Devotee{" "}
                      {id === 0 ? "*" : ""}
                    </label>
                    <TextField
                      fullWidth
                      name="fullName"
                      id="fullName"
                      value={item.fullName}
                      variant="standard"
                      onChange={(e: any) =>
                        props.updateMemberInfo(id, {
                          ...item,
                          fullName: e.target.value,
                        })
                      }
                      error={!!props.fullNameError}
                    />
                    <FormHelperText
                      className="errorText"
                      style={{
                        display:
                          props.fullNameError && id < 1 ? "block" : "none",
                      }}
                    >
                      Please enter your full name
                    </FormHelperText>
                  </Box>
                </div>
              );
            })}
            <Box className="formField">
              <label>Gotram (Default Kashyap)</label>
              <TextField
                fullWidth
                name="Gotram"
                id="Gotram"
                value={props?.gotram}
                variant="standard"
                onChange={(e: any) => {
                  props.addGotram(e.target.value);
                }}
              />
            </Box>
            <Box className="formField">
              <FormGroup className="formCheck">
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Don’t know my Gotram"
                />
              </FormGroup>
            </Box>
            {singleCartData?.orderType !== orderType.chadhava && (
              <Box className="formField">
                <label>Manokamna/ Wish (Optional)</label>

                <TextField
                  className="monokamnaDetails"
                  id="standard-basic"
                  placeholder="Enter here"
                  multiline
                  rows={4}
                  fullWidth
                  value={props?.manokamna}
                  onChange={(e: any) => {
                    props.addmanokamna(e.target.value);
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* ---- Previous Booking Devotee Modal ---- */}
      <Dialog
        className="startPostInput"
        onClose={() => props.setPopUpDevoteePreviousBooking(false)}
        open={props.popUpDevoteePreviousBooking}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "14px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, pb: 1, fontSize: "17px", fontWeight: "600" }}
        >
          Previous Booking Devotee List
        </DialogTitle>
        <Button
          aria-label="close"
          color="primary"
          onClick={() => props.setPopUpDevoteePreviousBooking(false)}
          sx={{
            position: "absolute",
            right: 0,
            top: 10,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <CloseIcon />
        </Button>
        <DialogContent sx={{ p: 1, width: "400px", maxWidth: "100%" }}>
          <Box sx={{ display: "flex", marginLeft: "15px" }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                // onChange={(e) => console.log("Radio change", e)}
              >
                {previousUserBookList.map((item: any, id: any) => {
                  return (
                    <FormControlLabel
                      value={item.id}
                      control={<Radio />}
                      label={item.fullName}
                      onClick={(e) => {
                        e.preventDefault();
                        props.selectPrevGroupMember(item);
                        console.log("Item selcetd", item);
                        // Write function to add that devotee to next blank area
                      }}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              width: "100%",
              backgroundColor: "#d56939",
              color: "#fff",
              m: 1,
              mb: "12px",
              padding: "8px",
              borderRadius: "30px",
              "&:hover": {
                backgroundColor: "rgb(149, 73, 39)",
              },
            }}
          >
            Select & Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        className="saveAndContinueBtn"
        onClick={() => props.proceedToCart()}
      >
        Save And Continue
      </Button>
    </Box>
  );
}

export default AddDevoteeDetailsView;
