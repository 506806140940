import { Box } from "@mui/material";
import PujaInfoView from "../../Views/Puja/PujaInfoView";
import ParentView from "../../Views/PrarentView/ParentView";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTempleDetailsById,
  getPriceInfoOfPujaAndPackage,
  getTempleDetailsByNameKey,
} from "../../store/actions/templeAction";
import dayjs from "dayjs";
import {
  setSelectedPuja,
  setSelectedPujaDateAndTime,
  setSelectedTempleDetailsForPuja,
} from "../../store/slices/pujaSlice";
import {
  dayNameToNumber,
  getDateFormatedForMUISelection,
  uid,
} from "../../utils/HelperFunctions";
import { updateCartAction } from "../../store/actions/cartAction";
import moment from "moment";
import { CouponService } from "td_server";
import { orderType } from "../../utils/Constants";
function PujaInfoScreen(props: any) {
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [pujaDetails, setPujaDetails] = useState<any>(null);
  const [isDateTimePicker, setIsDateTimePicker] = useState(false);
  const [popupPujaAlreadyAdded, setPopupPujaAlreadyAdded] = useState(false);
  const [pujaDateAndTime, setPujaDateAndTime] = useState({
    pujaDate: "",
    pujaTime: "10AM",
  });
  const [packageSelected, setPackageSelected]: any = useState(null);
  const [avialableCouponCode, setAvailableCouponCode] = useState(null);
  const [pujaPriceInfo, setPujaPriceInfo] = useState(null);
  const handleSetPujaDateAndTime = (date: any) => {
    setPujaDateAndTime(date);
  };
  const { selectedTempleDetails, benefitsList } = useSelector(
    (state: any) => state.temple
  );

  const { cartInfo } = useSelector((state: any) => state.cart);
  const templeAndPujaInfoPath = location.pathname;
  useEffect(() => {
    let keyType = props?.keyType;

    if (keyType && keyType === "id") {
      const templeIdComing = location.pathname.split("/")[2];
      dispatch(getTempleDetailsById(templeIdComing))
        .then((res: any) => console.log("Response"))
        .catch((err: any) => console.log("Error in fetching temple", err));
    } else {
      // :templeNameKey/pujaData/:pujaNameKey
      // Parse the URL parameters
      const params = new URLSearchParams(location.search);
      // console.log("templeNameKey==",params.get('templeNameKey'))
      // console.log("pujaNameKey==",params.get('pujaNameKey'))
      let templeIdComing = params.get("templeNameKey");
      dispatch(getTempleDetailsByNameKey(templeIdComing))
        .then((res: any) => console.log("Response"))
        .catch((err: any) => console.log("Error in fetching temple", err));
    }

    // }
  }, []);

  useEffect(() => {
    console.log("new cart tusar code:");
    if (selectedTempleDetails) {
      const pujaListForTemple = selectedTempleDetails.templePuja;
      let keyType = props?.keyType;
      let selectedPujaDetails = null;
      if (keyType === "id") {
        const pujaId = templeAndPujaInfoPath.split("/")[4];
        selectedPujaDetails = pujaListForTemple.filter(function (item: any) {
          return item.pujaId === pujaId;
        });
      } else {
        const params = new URLSearchParams(location.search);
        const pujaId = params.get("pujaNameKey");
        selectedPujaDetails = pujaListForTemple.filter(function (item: any) {
          return item.pujaNameKey === pujaId;
        });
      }

      if (selectedPujaDetails.length > 0) {
        setPujaDetails(selectedPujaDetails[0]);
        detectAndSelectPackage(selectedPujaDetails[0]);
      }

    }
  }, [selectedTempleDetails]);

  useEffect(() => {
    fetchCouponForPuja(selectedTempleDetails, pujaDetails, packageSelected);
    console.log("use effect ran for coupon fetch");
  }, [selectedTempleDetails, pujaDetails, packageSelected]);

  const fetchCouponForPuja = async (
    selectedTempleDetails: any,
    pujaDetails: any,
    packageSelected: any
  ) => {
    console.log(
      "Temple puja pkg  response",
      selectedTempleDetails,
      pujaDetails,
      packageSelected
    );
    if (selectedTempleDetails && pujaDetails) {
      let response1 = await CouponService.getActiveCouponsByPujaIdCurrentDate(
        selectedTempleDetails?.id,
        pujaDetails?.pujaId,
        packageSelected?.packageId
      );
      console.log("Response 1 ", response1);
      if (response1?.couponList && response1?.couponList?.length > 0) {
        setAvailableCouponCode(response1.couponList[0]);
      }

      // else {
      //   let response2 = await CouponService.getActiveCouponsByPujaIdCurrentDate(
      //     selectedTempleDetails?.id,
      //     pujaDetails?.pujaId,
      //     "all"
      //   );
      //   if (response2?.couponList && response2?.couponList?.length > 0) {
      //     setAvailableCouponCode(response2.couponList[0]);
      //   }
      // }
    } else {
      try {
        let response3 = await CouponService.getAllActiveCouponsByDate();
        console.log("Coupon code For all available", response3);
        if (response3?.couponList && response3?.couponList?.length > 0) {
          setAvailableCouponCode(response3.couponList[0]);
        }
      } catch (error: any) {
        console.log("Error in coupon fetch", error);
      }
    }
  };

  console.log("Coupon code texzt", avialableCouponCode);

  const detectAndSelectPackage = (selectedPuja: any) => {
    if (
      selectedPuja.multiplePujaDetails &&
      selectedPuja.multiplePujaDetails.length > 0
    ) {
      let packages = selectedPuja.multiplePujaDetails;
      let filterMostUsed = packages.filter(
        (item: any) => item.isMostPopular === true
      );
      if (filterMostUsed && filterMostUsed.length > 0) {
        setPackageSelected(filterMostUsed[0]);
      } else {
        setPackageSelected(packages[0]);
      }
    }
    //Package data not present
    else {
    }
  };

  // const navigateToPujaDetailsAdditionScreen = () => {
  //   navigate("/checkout/puja-details");
  // };

  // const dayNameToNumber = (dayName: string): number | null => {
  //   const dayMapping: { [key: string]: number } = {
  //     Sun: 0,
  //     Mon: 1,
  //     Tue: 2,
  //     Wed: 3,
  //     Thu: 4,
  //     Fri: 5,
  //     Sat: 6,
  //   };

  //   return dayMapping[dayName] !== undefined ? dayMapping[dayName] : null;
  // };

  const [isSingleDatePuja, setIsSingleDatePuja] = useState(false);
  const checkSingleDatePuja = (): any => {
    if (!pujaDetails) {
      return false;
    }

    if (
      typeof pujaDetails?.restrictionDate === "undefined" ||
      pujaDetails?.restrictionDate?.length === 0
    ) {
      return false;
    }
    const validPujaDate: string[] = pujaDetails?.restrictionDate?.filter(
      (item: any) => Date.parse(item)
    );

    if (
      pujaDetails?.restrictionDate?.length === 1 &&
      validPujaDate.length === 1
    ) {
      setIsSingleDatePuja(true);
      setPujaDateAndTime({
        pujaDate: new Date(validPujaDate[0])?.toDateString(),
        pujaTime: "10AM",
      });
    } else {
      setIsSingleDatePuja(false);
    }
  };
  useEffect(() => {
    checkSingleDatePuja();
  }, [pujaDetails?.restrictionDate]);

  useEffect(() => {
    if (selectedTempleDetails && pujaDetails && packageSelected) {
      getPriceInfo(
        selectedTempleDetails?.id,
        pujaDetails.pujaId,
        packageSelected?.packageId
      );
    } else if (selectedTempleDetails && pujaDetails) {
      getPriceInfo(selectedTempleDetails?.id, pujaDetails.pujaId, null);
    } else {
    }
  }, [selectedTempleDetails, pujaDetails, packageSelected]);

  useEffect(() => {
    if (selectedTempleDetails && pujaDetails && packageSelected) {
      getPriceInfo(
        selectedTempleDetails?.id,
        pujaDetails.pujaId,
        packageSelected?.packageId
      );
    } else if (selectedTempleDetails && pujaDetails) {
      getPriceInfo(selectedTempleDetails?.id, pujaDetails.pujaId, null);
    } else {
    }
  }, [selectedTempleDetails, pujaDetails, packageSelected]);

  const shouldDisableDate = (date: any): any => {
    if (!pujaDetails) {
      return false;
    }

    if (
      typeof pujaDetails?.restrictionDate === "undefined" ||
      pujaDetails?.restrictionDate?.length === 0
    ) {
      return false;
    }

    const dayNames: string[] = pujaDetails?.restrictionDate?.filter(
      (item: any) => isNaN(Date.parse(item))
    );

    const specificDates: Date[] = pujaDetails?.restrictionDate
      ?.filter((item: any) => !isNaN(Date.parse(item)))
      ?.map((dateString: any) => new Date(dateString));

    // Convert day names to numbers
    const disableWeekDays: number[] = dayNames
      ?.map(dayNameToNumber)
      ?.filter((day) => day !== null) as number[];

    const day: number = date.day();
    if (disableWeekDays?.includes(day)) {
      return false;
    }

    return !specificDates?.some(
      (disabledDate) =>
        date?.date() === disabledDate?.getDate() &&
        date?.month() === disabledDate?.getMonth() &&
        date?.year() === disabledDate?.getFullYear()
    );
  };

  const preprocessingAndProceedToAddmemberScreen = () => {
    if (pujaDateAndTime.pujaDate) {
      dispatch(setSelectedTempleDetailsForPuja(selectedTempleDetails));
      dispatch(setSelectedPuja(pujaDetails));
      //Setting all puja date to 10 AM
      const formattedDateToSave = getDateFormatedForMUISelection(
        pujaDateAndTime.pujaDate?.toString()
      );
      // console.log("formattedDate", formattedDateToSave);
      let dateTimeData = {
        pujaDate: formattedDateToSave,
        pujaTime: "10:30AM",
      };
      dispatch(setSelectedPujaDateAndTime(dateTimeData));

      let cartDataPayload: any = {
        id: uid(),
        members: [],
        pujaDetails: pujaDetails,
        templeDetails: selectedTempleDetails,
        pujaDateTime: dateTimeData,
        pujaPackage: packageSelected,
        orderType: orderType.puja,
      };
      let newCartData = [];
      newCartData.push(cartDataPayload);
      dispatch(updateCartAction(newCartData));
      // navigate("/checkout/puja-details");
      navigate("/checkoutV2/addDevotee");
      // dispatch(setPujaMembers(selectedCartItem.members));
    } else {
    }
  };

  const getPriceInfo = async (templeId: any, pujaId: any, packageId: any) => {
    let payload = {
      templeId: templeId,
      pujaId: pujaId,
      packageId: packageId,
    };
    console.log("Payload", payload);
    try {
      dispatch(getPriceInfoOfPujaAndPackage(payload))
        .then((response: any) => {
          console.log("Puja price info response", response);
          setPujaPriceInfo(response);
        })
        .catch((error: any) => {
          console.log("Error in puja price fetch", error);
        });
      // console.log("Fetch function screen price res", responseData);
    } catch (error) {
      console.log("Error fetching price inf");
    }
  };
  const queryParams = new URLSearchParams(location.search);
  const templeNameKey = queryParams.get('templeNameKey');
  const pujaNameKey = queryParams.get('pujaNameKey');
  const navigateToPujaStepsView = () => {
    navigate(`/pujaSteps?templeNameKey=${templeNameKey}&pujaNameKey=${pujaNameKey}`);
  };
  const navigateToSeeVideoView = () => {
    navigate("/pujaVideo");
  };
  return (
    <ParentView>
      <PujaInfoView
        cartInfo={cartInfo}
        pujaDetails={pujaDetails}
        shouldDisableDate={shouldDisableDate}
        selectedTempleDetails={selectedTempleDetails}
        preprocessingAndProceedToAddmemberScreen={
          preprocessingAndProceedToAddmemberScreen
        }
        isSingleDatePuja={isSingleDatePuja}
        benefitsList={benefitsList}
        setIsDateTimePicker={setIsDateTimePicker}
        isDateTimePicker={isDateTimePicker}
        pujaDateAndTime={pujaDateAndTime}
        setPujaDateAndTime={handleSetPujaDateAndTime}
        popupPujaAlreadyAdded={popupPujaAlreadyAdded}
        setPopupPujaAlreadyAdded={setPopupPujaAlreadyAdded}
        packageSelected={packageSelected}
        setPackageSelected={setPackageSelected}
        navigateToPujaStepsView={navigateToPujaStepsView}
        navigateToSeeVideoView={navigateToSeeVideoView}
        avialableCouponCode={avialableCouponCode}
        pujaPriceInfo={pujaPriceInfo}
      />
    </ParentView>
  );
}

export default PujaInfoScreen;
